import { gql } from '@apollo/client';

import type { ContentfulTypedSysProps } from '../../../../types/contentful';

export const bookmarkFragment = gql`
  fragment BookmarkAll on Bookmark {
    sys {
      id
      publishedAt
      firstPublishedAt
    }
    title
    body
    analyticsId
  }
`;

export interface BookmarkProps extends ContentfulTypedSysProps<'Bookmark'> {
  sys: {
    id: string;
    publishedAt: Date;
    firstPublishedAt: Date;
  };
  title: string;
  body: string;
  analyticsId: string;
}

export const bookmarkQuery = gql`
  query BookmarkQuery($id: String!, $preview: Boolean!, $locale: String!) {
    bookmark(id: $id, preview: $preview, locale: $locale) {
      ...BookmarkAll
    }
  }
  ${bookmarkFragment}
`;

export interface BookmarkDataProps extends ContentfulTypedSysProps<'Bookmark'> {
  bookmark?: BookmarkProps;
}
