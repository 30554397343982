import { cx } from '@emotion/css';
import { useImperativeEffect } from '@snapchat/core-browser';
import { BackgroundColor, MotifScheme } from '@snapchat/snap-design-system-marketing';
import type { FC } from 'react';
import { useCallback, useContext, useRef, useState } from 'react';

import type { EventMedia } from '../../../../components/EventPlayer/types';
import { Feature, useFeatureFlags } from '../../../../components/FeatureFlags';
import { PageLayoutContext } from '../../../../context/PageLayoutContext';
import { logUserEvent, logWarning } from '../../../../helpers/logging/loggingInstance';
import { UserAction } from '../../../../types/events';
import { getRegistrationLocalStorageData } from '../../utils/getRegistrationLocalStorageData';
import { BookmarkToastShallow } from '../BookmarkToast/BookmarkToast';
import type { VideoFrameProps } from '../VideoFrame/VideoFrame';
import { VideoFrame } from '../VideoFrame/VideoFrame';
import type { LiveEventProps } from './liveEventQuery';
import { darkModeCss, liveEventWrapperCss } from './LiveState.styled';
type Props = Pick<LiveEventProps, 'stream'>;

export const LiveState: FC<Props> = props => {
  const { stream } = props;

  const features = useFeatureFlags();
  const enableBitmoji = features[Feature.EXPERIENCE_ENABLE_BITMOJI] === 'true';
  const enableCaptions = features[Feature.EXPERIENCE_ENABLE_CAPTIONS] === 'true';
  const enableBookmarks = features[Feature.EXPERIENCE_ENABLE_BOOKMARKS] === 'true';
  const enableAsl = features[Feature.EXPERIENCE_ENABLE_ASL_STREAM] === 'true';
  const enableDarkModeForLive = features[Feature.EXPERIENCE_ENABLE_DARK_MODE_FOR_LIVE] === 'true';

  const { setHeaderBackgroundColorOverride } = useContext(PageLayoutContext);

  const seenBookmarksSetRef = useRef<Set<string>>(new Set([]));
  const [bookmarkId, setBookmarkId] = useState<string | null>(null);
  const [showBookmark, setShowBookmark] = useState(false);

  const onReceiveBookmark = useCallback(
    (bookmark: string) => {
      if (!enableBookmarks) {
        return;
      }

      // Ignore the bookmark if we've seen received it before. This shouldn't happen so log an error if so.
      if (seenBookmarksSetRef.current.has(bookmark)) {
        logWarning({
          component: 'LiveState',
          message: `Received duplicate bookmark id: ${bookmark}`,
        });

        return;
      }

      setBookmarkId(bookmark);
      setShowBookmark(true);
      seenBookmarksSetRef.current = new Set([...seenBookmarksSetRef.current, bookmark]);
    },
    [enableBookmarks]
  );

  const eventMedia: EventMedia = {
    src: stream.streamUrl!,
    aslSrc: enableAsl ? stream.aslStreamUrl : undefined,
    backupSrc: stream.backupStreamUrl,
    backupAslSrc: stream.backupAslStreamUrl,
    poster: stream.posterImage.url,
    title: stream.title,
    textTracks: enableCaptions
      ? stream.captionsCollection?.items.map(caption => ({
          languageCode: caption.sourceLanguage,
          label: caption.label,
          src: caption.sourceUrl!,
          kind: caption.kind ?? 'subtitles',
        }))
      : undefined,
  };

  const videoFrameProps: VideoFrameProps = {
    videoId: stream.sys.id,
    eventMedia,
    bitmojiProps: {
      enableBitmoji,
      videoId: stream.sys.id,
    },
    analyticsId: stream.analyticsId,
    isLiveEvent: true,
    timestampBehavior: 'Use Manifest Timestamps',
    bufferStallTimeout: stream.bufferStallTimeout,
    onReceiveBookmark,
  };

  // Update header background and log user interests from registration
  useImperativeEffect(() => {
    enableDarkModeForLive && setHeaderBackgroundColorOverride?.(BackgroundColor.Black);
  }, [enableDarkModeForLive, setHeaderBackgroundColorOverride]);

  // Look for any "Interests__c" form values captured in local storage and log them as user interests
  useImperativeEffect(() => {
    const storedRegistrationData = getRegistrationLocalStorageData();

    Object.entries(storedRegistrationData).forEach(([_, registration]) => {
      if (!Array.isArray(registration?.Interests__c)) return;

      registration.Interests__c.forEach(interest =>
        logUserEvent({
          eventAction: UserAction.Open,
          eventCategory: 'LiveEvent',
          eventLabel: `interest: ${interest?.id}`,
        })
      );
    });
  }, []);

  return (
    <div
      className={cx(liveEventWrapperCss, {
        [MotifScheme.SECONDARY]: enableDarkModeForLive,
        [darkModeCss]: enableDarkModeForLive,
      })}
    >
      {enableBookmarks && bookmarkId && (
        <BookmarkToastShallow
          open={showBookmark}
          sys={{ id: bookmarkId }}
          onBookmark={() => {
            setShowBookmark(false);
          }}
          onClose={() => {
            setShowBookmark(false);
          }}
          autoCloseTimeMs={10e3}
          __typename="Bookmark"
        />
      )}
      <VideoFrame {...videoFrameProps} />
    </div>
  );
};
