import { Button, FormattedMessage } from '@snapchat/snap-design-system-marketing';
import type { FC } from 'react';
import type { ContentfulTypedSysProps } from 'src/types/contentful';

import { logUserEvent } from '../../../../helpers/logging/loggingInstance';
import type { ContentfulIdVariable } from '../../../../hooks/useContentfulQuery';
import { useContentfulQuery } from '../../../../hooks/useContentfulQuery';
import { UserAction } from '../../../../types/events';
import type { ToastProps } from '../Toast/Toast';
import { Toast } from '../Toast/Toast';
import type { BookmarkDataProps } from './bookmarkQuery';
import { bookmarkQuery } from './bookmarkQuery';
import { bookmarkToastCss, bookmarkToastTextCss } from './BookmarkToast.styles';

interface BookmarkToastProps extends ToastProps {
  onBookmark: () => void;
  /** If specified, logs user interactions with the bookmark toast. */
  analyticsId?: string;
}

/**
 * Toast with a button to trigger for bookmarking.
 *
 * TODO: Move the text here into Contentful when content is created.
 */
export const BookmarkToast: FC<BookmarkToastProps> = props => {
  const { children, analyticsId, onBookmark, ...toastProps } = props;

  return (
    <Toast className={bookmarkToastCss} {...toastProps}>
      <p className={bookmarkToastTextCss}>{children}</p>
      <Button
        size="Flat"
        type="Flat"
        iconName="heart-outline"
        onClick={() => {
          analyticsId &&
            logUserEvent({
              eventAction: UserAction.Click,
              eventCategory: 'BookmarkToast',
              eventLabel: analyticsId,
            });

          onBookmark();
        }}
      >
        <FormattedMessage id="bookmarkCta" defaultMessage="Bookmark this moment" />
      </Button>
    </Toast>
  );
};

type BookmarkToastShallowProps = ContentfulTypedSysProps<'Bookmark'> &
  Omit<BookmarkToastProps, 'analyticsId'>;

export const BookmarkToastShallow: FC<BookmarkToastShallowProps> = props => {
  const { data } = useContentfulQuery<BookmarkDataProps, ContentfulIdVariable>(bookmarkQuery, {
    variables: { id: props.sys.id },
  });

  if (!data?.bookmark?.body) return null;

  return (
    <BookmarkToast {...props} analyticsId={data.bookmark.analyticsId}>
      {data.bookmark.body}
    </BookmarkToast>
  );
};

BookmarkToastShallow.displayName = 'BookmarkToast';
