import { gql } from '@apollo/client';

import type { MediaDataProps } from '../../../../components/Media';
import { assetFragment } from '../../../../components/Media';
import type { ContentfulSysProps, ContentfulTypedSysProps } from '../../../../types/contentful';
import type { Items } from '../../../../types/Items';
import { contentfulSysIdFragment } from '../../../../utils/contentful/contentfulQuery';
import type { BookmarkProps } from '../BookmarkToast/bookmarkQuery';
import { bookmarkFragment } from '../BookmarkToast/bookmarkQuery';
import type { HighlightProps } from '../Highlight/Highlight.types';
import { hightlightFragment } from '../Highlight/HighlightsQuery';
import type { StreamProps } from '../Stream/streamQuery';
import { streamFragment } from '../Stream/streamQuery';

// ============================================================================
// Event all fragment
// ============================================================================

export type LiveEventState = 'pre' | 'post' | 'live';

export const liveEventFragment = gql`
  fragment LiveEventAll on LiveEvent {
    ...ContentfulSysId
    sys {
      publishedVersion
    }
    eventState
    isPolling
    pollingIntervalMs
    pageReloadReasons
    backupRedirectUrl
    bookmarks {
      ...ContentfulSysId
      bookmarksCollection(limit: 30) {
        items {
          ...BookmarkAll
        }
      }
    }
    highlightsSectionTitle
    highlightsCollection {
      items {
        ...HighlightAll
      }
    }
    stream {
      ...StreamAll
    }
    preEventBlocksCollection {
      items {
        ...ContentfulSysId
      }
    }
    logoVideo {
      ...AssetAll
    }
    analyticsId
  }
  ${contentfulSysIdFragment}
  ${bookmarkFragment}
  ${hightlightFragment}
  ${streamFragment}
  ${assetFragment}
`;

/** Event on the experience.snap.com website. We usually have one for each year and a few test ones. */
export interface LiveEventProps extends ContentfulTypedSysProps<'LiveEvent'> {
  sys: {
    id: string;
    publishedVersion: number;
  };
  eventState: LiveEventState;
  isPolling: boolean;
  pollingIntervalMs?: number;
  pageReloadReasons?: string;
  backupRedirectUrl?: string;
  highlightsSectionTitle?: string;
  highlightsCollection: Items<HighlightProps>;
  bookmarks: ContentfulTypedSysProps<'Bookmarks'> & {
    bookmarksCollection: Items<BookmarkProps>;
  };
  stream: StreamProps;
  preEventBlocksCollection: Items<ContentfulSysProps>;
  logoVideo: MediaDataProps;
  analyticsId: string;
}

// ============================================================================
// Event polled content fragment (used for state transition)
// ============================================================================

export const liveEventRealtimeFragment = gql`
  fragment LiveEventRealtime on LiveEvent {
    ...ContentfulSysId
    sys {
      publishedVersion
      publishedAt
    }
    eventState
    isPolling
    pollingIntervalMs
    pageReloadReasons
    backupRedirectUrl
  }
  ${contentfulSysIdFragment}
`;

export type LiveEventRealtimeProps = Pick<
  LiveEventProps,
  'eventState' | 'isPolling' | 'pollingIntervalMs' | 'pageReloadReasons' | 'backupRedirectUrl'
> & {
  sys: {
    id: string;
    publishedVersion?: number;
    updatedAt?: string; // only in webhook events
    publishedAt?: string; // not in publish (prod) webhook events :(
  };
};

// ============================================================================
// Event all query
// ============================================================================

export const liveEventQuery = gql`
  query LiveEventQuery($id: String!, $preview: Boolean!, $locale: String!) {
    liveEvent(id: $id, preview: $preview, locale: $locale) {
      ...LiveEventAll
    }
  }
  ${liveEventFragment}
`;

export interface LiveEventDataProps {
  liveEvent: LiveEventProps;
}

// ============================================================================
// Event polled content query
// ============================================================================

export const liveEventRealtimeQuery = gql`
  query LiveEventRealtimeQuery($id: String!, $preview: Boolean!, $locale: String!) {
    liveEvent(id: $id, preview: $preview, locale: $locale) {
      ...LiveEventRealtime
    }
  }
  ${liveEventRealtimeFragment}
`;

export interface LiveEventRealtimeDataProps {
  liveEvent?: LiveEventRealtimeProps;
}
