import type { BrowserOptions } from '@sentry/browser';
/**
 * Moved all types related to site configuration into this file. Addresses issue in ts-node not
 * supportig absolute paths
 */
import type { Partition } from '@snapchat/graphene';

export enum LoggingVendor {
  /** Snap's internal Blizzad logs. Client and server. */
  BLIZZARD = 'Blizzard',

  /** Regular stdout/stderr console. Client only. */
  CONSOLE = 'Console',

  /**
   * Google Tag Manager. Client and server. TODO: Rename to GOOGLE_TAG_MANAGER not to be confused w/
   * GOOGLE_CLOUD_*
   */
  GOOGLE = 'Google',

  /** Google Cloud Logging. Server only. */
  GOOGLE_CLOUD_LOGGING = 'GoogleCloudLogging',

  /** Google Cloud Monitoring. Server Only. ` */
  GOOGLE_CLOUD_MONITORING = 'GoogleCloudMonitoring',

  /** Snap hosted Graphene. Client and server. */
  GRAPHENE = 'Graphene',

  /** Send event data to /api/events to process them server side. Client Only. */
  MWP_HERMES = 'MwpClientEventExport',

  /** Snap hosted Sentry. Client and server. */
  SENTRY = 'Sentry',
}

export enum SubscribedEventType {
  /** An event for ecommerce, like adding items to cart or checking out. */
  ECOMMERCE = 'ecommerce',
  /** Raised when a generic error occurs */
  ERROR = 'error',
  /** Event for recording a result of an experiment having an impression. */
  EXPERIMENT_IMPRESSION = 'experiment_impression',
  /**
   * Raised on first Client Side Page load
   *
   * @deprecated - This is wildly inaccurate.
   */
  FIRST_PAGE_LOAD = 'first_page_load',
  /** A single event for a non-user interaction, like video loaded event, carousel restarted etc */
  INTERNAL = 'internal',
  /** An event measuring a span of time. For this event the duration is mandatory. */
  INTERNAL_TIMING = 'internal_timing',
  /** A single event for a non-user interaction, used when there is a value to report. */
  INTERNAL_VALUE = 'internal_value',
  /** Raised on each Client Side Page load including client-only navigation. */
  PAGE_LOAD = 'page_load',
  /** @deprecated - Event that happens in the phone number form component */
  PHONE_NUMBER_EVENT = 'phone_number_event',
  /** Raised on each user interaction event (button clicks, form submissions, etc.) */
  USER_INTERACTION = 'user_interaction',
  /** Event indicating a condition that should never happen. But it is not fatal. */
  WARNING = 'warning',
}

export enum BlizzardEventFormat {
  /** Adds additional metrics and formatting for the standard Blizzard data store for MWP sites */
  DEFAULT = 'Default',
  /** Adds additional metrics and formatting required for the ForBusiness.snapchat.com website */
  FOR_BUSINESS = 'ForBusiness',
}

export interface GrapheneEventListenerConfig {
  vendor: LoggingVendor.GRAPHENE;
  /**
   * Name of the Graphene property that we want the logs to be attributed to.
   *
   * Note: Prefer to use WEB_COMMON for shared events across all sites. Variant will be adjusted per
   * site.
   */
  partitionName: Partition;
}

export interface GoogleEventListenerConfig {
  vendor: LoggingVendor.GOOGLE;

  /** Id for the tagmanager.google.com. */
  googleTagManagerId: string;

  /** Analytics id for analytics.google.com. This will started with 'UA-' for non GA4 properties. */
  googleAnalyticsId?: string;
}

export interface GoogleCloudLoggingEventListenerConfig {
  vendor: LoggingVendor.GOOGLE_CLOUD_LOGGING;
}

export interface GoogleCloudMonitoringEventListenerConfig {
  vendor: LoggingVendor.GOOGLE_CLOUD_MONITORING;
}

export interface MwpClientEventExportEventListnerConfig {
  vendor: LoggingVendor.MWP_HERMES;
}
export interface BlizzardEventListenerConfig {
  vendor: LoggingVendor.BLIZZARD;
  /** Defaults to BlizzardEventFormat.DEFAULT */
  eventFormat?: BlizzardEventFormat;
}

export interface SentryEventListenerConfig {
  vendor: LoggingVendor.SENTRY;
  /**
   * Public URL where to send sentry logs. Found in:
   * https://sentry.sc-corp.net/settings/sentry/projects/<sentry-name>/keys/
   */
  dsn: string;

  /** Name of the Sentry project name, i.e. 'ar'. It's part of the URL. */
  projectName: string;

  /**
   * Required prop for `@snapchat/sentry` package. The `@snapchat/logging-browser` package has logic
   * to set a default value that matches all requests if not specified.
   *
   * Per Sentry documentation:
   *
   * A pattern for error URLs which should exclusively be sent to Sentry. This is the opposite of
   * Options.denyUrls. By default, all errors will be sent.
   */
  allowUrls?: BrowserOptions['allowUrls'];
}

export interface ConsoleEventListenerConfig {
  vendor: LoggingVendor.CONSOLE;
}

export type EventListenerConfig = (
  | GrapheneEventListenerConfig
  | GoogleEventListenerConfig
  | GoogleCloudLoggingEventListenerConfig
  | GoogleCloudMonitoringEventListenerConfig
  | MwpClientEventExportEventListnerConfig
  | BlizzardEventListenerConfig
  | SentryEventListenerConfig
  | ConsoleEventListenerConfig
)[];
