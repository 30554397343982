import { cx } from '@emotion/css';
import { useImperativeEffect } from '@snapchat/core-browser';
import { BackgroundColor } from '@snapchat/snap-design-system-marketing';
import { type FC, useContext } from 'react';

import { Feature, useFeatureFlags } from '../../../../components/FeatureFlags';
import { PageLayoutContext } from '../../../../context/PageLayoutContext';
import { renderRichText } from '../../../../utils/renderText/renderRichText';
import { Highlight } from '../Highlight';
import { VideoPreview } from '../VideoPreview/VideoPreview';
import type { LiveEventProps } from './liveEventQuery';
import {
  blurbClass,
  blurbWithAnimationClass,
  highlightsSectionCss,
  highlightsTitleCss,
  learnMoreClass,
  logoVideoCss,
  streamReplayContainerCss,
  streamReplayInnerWrapperCss,
  videoPreviewClass,
} from './PostState.styled';

type Props = Pick<
  LiveEventProps,
  'stream' | 'highlightsCollection' | 'highlightsSectionTitle' | 'logoVideo'
>;

/** Displays the Video-on-Demand (VOD) from the stream. */
export const PostState: FC<Props> = props => {
  const { stream, highlightsCollection, highlightsSectionTitle, logoVideo } = props;
  const features = useFeatureFlags();
  const enableBitmoji = features[Feature.EXPERIENCE_ENABLE_BITMOJI] === 'true';
  const isolateStreamReplay = features[Feature.EXPERIENCE_SHOW_ISOLATED_STREAM_REPLAY] === 'true';
  const { setHeaderBackgroundColorOverride } = useContext(PageLayoutContext);

  // Header background may have been dynamically changed to black if dark mode is enabled.
  // Ensure it is reset to white on the post-state page.
  useImperativeEffect(() => {
    setHeaderBackgroundColorOverride?.(BackgroundColor.White);
  }, [setHeaderBackgroundColorOverride]);

  return (
    <>
      <article
        className={cx(streamReplayContainerCss, {
          isolated: isolateStreamReplay,
        })}
      >
        <div className={cx(streamReplayInnerWrapperCss, { isolated: isolateStreamReplay })}>
          <VideoPreview
            className={videoPreviewClass}
            videoTitle={stream.title}
            videoUrl={stream.vodUrl}
            aslUrl={stream.aslVodUrl}
            videoBackgroundImage={stream.vodThumbnail}
            captionsCollection={stream.vodCaptionsCollection}
            bitmojiProps={{
              enableBitmoji,
              videoId: stream.sys.id,
            }}
            timestampBehavior={stream.vodTimestampBehavior}
            progressOffset={stream.vodProgressOffset}
            analyticsId={stream.analyticsId}
          />
          <section className={blurbWithAnimationClass}>
            <video className={logoVideoCss} src={logoVideo.url} autoPlay muted />
            {renderRichText(stream.blurb ?? '')}
          </section>
          <p className={blurbClass}>{renderRichText(stream.blurb ?? '')}</p>
          {!isolateStreamReplay && (
            <img className={learnMoreClass} src="/learn-more.png" alt="Learn More" />
          )}
        </div>
      </article>
      {!isolateStreamReplay && (
        <>
          {highlightsSectionTitle && (
            <h2 className={highlightsTitleCss}>{highlightsSectionTitle}</h2>
          )}
          <section className={highlightsSectionCss}>
            {highlightsCollection.items.map(highlightProps => {
              return <Highlight key={highlightProps.sys.id} {...highlightProps} />;
            })}
          </section>
        </>
      )}
    </>
  );
};
