import { cx } from '@emotion/css';
import { useContentfulImages } from '@snapchat/mw-global-components';
import { Modal, Picture, VerticalAlignment } from '@snapchat/snap-design-system-marketing';
import type { FC } from 'react';
import { useState } from 'react';

import type { EventMedia, TimestampBehavior } from '../../../../components/EventPlayer/types';
import type { MediaDataProps } from '../../../../components/Media';
import { logUserEvent } from '../../../../helpers/logging';
import { UserAction } from '../../../../types/events';
import type { Items } from '../../../../types/Items';
import type { BitmojiProps } from '../BitmojiControls/types';
import type { CaptionsData } from '../Captions/captionsQuery';
import type { VideoFrameProps } from '../VideoFrame';
import { VideoFrame } from '../VideoFrame';
import {
  videoPreviewBackgroundImgCss,
  videoPreviewImageContainerCss,
  videoPreviewImageTimerCss,
  videoPreviewModalCss,
  videoPreviewPlayButtonCss,
} from './VideoPreview.styled';

interface VideoPreviewProps {
  className?: string;
  videoLength?: string;
  videoBackgroundImage?: MediaDataProps;
  videoTitle?: string;
  videoUrl?: string;
  captionsCollection?: Items<CaptionsData>;
  aslUrl?: string;
  bitmojiProps?: BitmojiProps;
  timestampBehavior?: TimestampBehavior;
  progressOffset?: number;
  analyticsId: string;
}

export const VideoPreview: FC<VideoPreviewProps> = ({
  className,
  videoTitle = '',
  videoUrl = '',
  videoLength,
  videoBackgroundImage,
  captionsCollection,
  aslUrl,
  bitmojiProps,
  timestampBehavior = 'Use Progress Timestamps',
  progressOffset,
  analyticsId,
}) => {
  const [showModal, setShowModal] = useState(false);

  const { getImageSources } = useContentfulImages();

  const imageClickHandler = () => {
    setShowModal(true);
  };

  const onCloseHandler = () => {
    logUserEvent({
      eventAction: UserAction.Click,
      eventCategory: 'VideoPreview',
      eventLabel: `${analyticsId}:close`,
    });
    setShowModal(false);
  };

  const imgFormatSrcs = videoBackgroundImage?.url && getImageSources(videoBackgroundImage.url);

  const eventMedia: EventMedia = {
    src: videoUrl,
    aslSrc: aslUrl,
    poster: videoBackgroundImage?.url,
    textTracks: captionsCollection?.items.map(caption => ({
      languageCode: caption.sourceLanguage,
      src: caption.sourceUrl!,
      kind: caption.kind ?? 'subtitles',
    })),
    title: videoTitle,
  };

  const videoFrameProps: VideoFrameProps = {
    eventMedia,
    bitmojiProps: {
      ...bitmojiProps,
    },
    isLiveEvent: false,
    timestampBehavior,
    progressOffset,
    analyticsId,
  };

  return (
    <>
      <section
        data-testid="video-preview"
        className={cx(videoPreviewImageContainerCss, className)}
        onClick={imageClickHandler}
      >
        {imgFormatSrcs && (
          <Picture imgSrcs={imgFormatSrcs} imgClassName={videoPreviewBackgroundImgCss} />
        )}
        <img className={videoPreviewPlayButtonCss} src="/play-button.svg" alt="Play Button" />
        {videoLength && <div className={videoPreviewImageTimerCss}>{videoLength}</div>}
      </section>
      {showModal && (
        <Modal
          isBlocking={false}
          verticalAlignment={VerticalAlignment.Middle}
          opacity={0.9}
          blur={5}
          isDisplayed={true}
          onClose={onCloseHandler}
          disableBackgroundScroll={true}
          // Make sure that scroll events which are actually drags of the volume slider don't get
          // cancelled by the modal's built in anti-scrolling behavior.
          shouldAllowScrollEvent={(event: Event) => {
            const target = event.target as HTMLElement | null;
            return target?.tagName === 'MEDIA-VOLUME-RANGE';
          }}
          className={videoPreviewModalCss}
        >
          <VideoFrame {...videoFrameProps} />
        </Modal>
      )}
    </>
  );
};
