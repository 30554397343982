import { gql } from '@apollo/client';
import type { RichText } from 'src/types/RichText';

import type { TimestampBehavior } from '../../../../components/EventPlayer/types';
import type { MediaDataProps } from '../../../../components/Media';
import { fragments as mediaFragments } from '../../../../components/Media';
import type { ContentfulTypedSysProps } from '../../../../types/contentful';
import type { Items } from '../../../../types/Items';
import { contentfulSysIdFragment } from '../../../../utils/contentful/contentfulQuery';
import type { CaptionsData } from '../Captions/captionsQuery';
import { captionsFragment } from '../Captions/captionsQuery';

export const streamFragment = gql`
  fragment StreamAll on Stream {
    ...ContentfulSysId
    title
    blurb {
      json
    }
    vodUrl
    streamUrl
    backupStreamUrl
    aslVodUrl
    aslStreamUrl
    backupAslStreamUrl
    vodThumbnail {
      ...AssetAll
    }
    posterImage {
      ...AssetAll
    }
    captionsCollection(limit: 8) {
      items {
        ...CaptionsAll
      }
    }
    vodCaptionsCollection(limit: 8) {
      items {
        ...CaptionsAll
      }
    }
    vodTimestampBehavior
    vodProgressOffset
    bufferStallTimeout
    analyticsId
  }
  ${contentfulSysIdFragment}
  ${captionsFragment}
  ${mediaFragments.all}
`;

export interface StreamProps extends ContentfulTypedSysProps<'Stream'> {
  title?: string;
  blurb?: RichText;
  vodUrl?: string;
  streamUrl?: string;
  backupStreamUrl?: string;
  aslVodUrl?: string;
  aslStreamUrl?: string;
  backupAslStreamUrl?: string;
  posterImage: MediaDataProps;
  captionsCollection: Items<CaptionsData>;
  vodCaptionsCollection: Items<CaptionsData>;
  vodThumbnail?: MediaDataProps;
  vodTimestampBehavior?: TimestampBehavior;
  vodProgressOffset?: number;
  bufferStallTimeout?: number;
  analyticsId: string;
}
